<template>
  <HeaderView v-if="intialized"></HeaderView>
  <router-view v-if="intialized" />
  <loader-overlay v-if="loading"></loader-overlay>
  <Notification v-if="notificationMessage" :message="notificationMessage" :type="notificationType" />
</template>

<script>
import HeaderView from '@/components/HeaderView.vue';
import LoaderOverlay from '@/components/LoaderOverlay.vue';
import Notification from '@/components/Notification.vue';

import { mapState } from 'vuex';

export default {
  name: 'App',
  components: {
    HeaderView,
    LoaderOverlay,
    Notification,
  },

  computed: {
    ...mapState([
      'isAuthenticated',
      'isOwner',
      'usrType',
      'loading',
      'intialized',
      'notificationMessage',
      'notificationType',
    ]),
  },
};
</script>

<style lang="scss">
@import '@/assets/css/style.scss';
</style>
