import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { checkToken } from "./services/authentication";
import VueLazyLoad from "vue3-lazyload";

const app = createApp(App);

app.use(router);
app.use(store);
app.use(VueLazyLoad);

checkToken(store);

app.mount("#app");

store.dispatch("initTheme");
store.dispatch("initApp");
