<template>
	<div class="loader">
		<LoaderCircle></LoaderCircle>
	</div>
</template>
<script>
import LoaderCircle from "@/components/LoaderCircle.vue";

export default {
	name: "LoaderOverlay",
	components: {
		LoaderCircle
	}
};
</script>

<style scoped>
.loader {
	position: fixed;
	left: 0;
	top: 0;
	z-index: 600;
	width: 100vw;
	height: 100vh;
	background: rgba(0, 0, 0, 0.43);
	backdrop-filter: blur(2.5px);
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>
