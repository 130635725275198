<template>
  <div class="notification" :data-type="type">
    {{ message }}
  </div>
</template>

<script>
export default {
  props: ['message', 'type'],
};
</script>

<style scoped>
.notification {
  position: fixed;
  top: 10px;
  right: 10px;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  animation: slide-in 0.3s ease-in-out;
  z-index: 9999999;
}

.notification[data-type='info'] {
  background-color: #05a5b1;
}

.notification[data-type='success'] {
  background-color: #1da302;
}

.notification[data-type='error'] {
  background-color: #db3434;
}

@keyframes slide-in {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
</style>
