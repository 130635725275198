import axios from 'axios';
import store from '@/store';

const BACKEND = process.env.NODE_ENV === 'production' ? '/ajax' : 'http://localhost:3000/ajax';

async function get(url, params = {}) {
  try {
    const query = new URLSearchParams(params).toString();
    const response = await axios.get(BACKEND + url + '?' + query, { headers: { 'Auth-Token': store.state.token } });
    return response.data;
  } catch (e) {
    showError(e);
    throw e;
  }
}

async function post(url, data = {}) {
  try {
    const response = await axios.post(BACKEND + url, data, { headers: { 'Auth-Token': store.state.token } });
    return response.data;
  } catch (e) {
    showError(e);
    throw e;
  }
}

async function _delete(url) {
  try {
    const response = await axios.delete(BACKEND + url, { headers: { 'Auth-Token': store.state.token } });
    return response.data;
  } catch (e) {
    showError(e);
    throw e;
  }
}

function showError(e) {
  if (e?.response?.data?.error) {
    store.dispatch('showNotification', {
      text: e.response.data.error,
      type: 'error',
    });
  } else {
    store.dispatch('showNotification', {
      text: e.message,
      type: 'error',
    });
  }
}

export default {
  get,
  post,
  _delete,
};
